const triggerCiButton = document.querySelector('#trigger-ci-button')
const loginButton = document.querySelector('#main-login-button')

document.querySelector('#main-login-button').onclick = function() {
  netlifyIdentity.open()
}

netlifyIdentity.on('init', () => {
  document.querySelectorAll('[data-show-on=logout]').forEach(showElement)
  document.querySelectorAll('[data-hide-on=logout]').forEach(hideElement)
})

netlifyIdentity.on('login', user => {
  console.info('user', user)

  if (!verifyTokenExpiration(user)) {
    console.info('Token expires. Logging out.')
    netlifyIdentity.logout()
  }

  document.querySelectorAll('[data-show-on=login]').forEach(showElement)
  document.querySelectorAll('[data-hide-on=login]').forEach(hideElement)

  triggerCiButton.onclick = function() {
    fetch('.netlify/functions/trigger_circle_ci_pipeline', {
      headers: {
        Authorization: `Bearer ${user.token.access_token}`
      }
    })

    alert('CircleCi is running test. Please check CircleCI project to see your build')
  }
})

netlifyIdentity.on('logout', () => {
  document.querySelectorAll('[data-show-on=logout]').forEach(showElement)
  document.querySelectorAll('[data-hide-on=logout]').forEach(hideElement)

  triggerCiButton.onclick = null
})

function showElement(element) {
  element.style = null
}

function hideElement(element) {
  element.style = 'display: none;'
}

function verifyTokenExpiration(user) {
  const expiresAt = user.token.expires_at

  return Date.now() < expiresAt
}
